import React, { useState } from "react";

import { Form, Row, Col, Button } from "react-bootstrap";

const AddAreaInfo = () => {
  const [info, setInfo] = useState({});

  const handleBlur = (e) => {
    const newInfo = { ...info };
    newInfo[e.target.name] = e.target.value;
    setInfo(newInfo);
    console.log(info);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData();

    console.log(info);
    formData.append("id", info.id);
    formData.append("systemID", info.sysID);
    formData.append("yearID", info.yearID);
    formData.append("areaID", info.areaID);
    formData.append("areaName", info.areaName);
    for (var value of formData.values()) {
      console.log(value);
    }

    /* here Api will be changed with the respected Api*/
    fetch("http://dev.payraapp.com/h/api/area/list/", {
      method: "POST",
 
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="boxContainer">
      <Form onSubmit={handleSubmit}>
        <h2>Add Area Info</h2>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Area Name</Form.Label>
              <Form.Control
                onBlur={handleBlur}
                name="areaName"
                type="text"
                placeholder="Enter Name"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>ID</Form.Label>

              <Form.Control
                onBlur={handleBlur}
                name="id"
                type="number"
                placeholder="Enter  ID"
              />
            </Form.Group>
          </Col>

          {/* <Col>
              <Form.Group controlId="formGridEmail">
                <Form.Label>Active</Form.Label>
                <Form.Select name="active" onBlur={handleBlur}  className="me-sm-2" id="inlineFormCustomSelect">
                  <option value="0">Choose...</option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                </Form.Select>
              </Form.Group>
            </Col> */}
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Area ID</Form.Label>

              <Form.Control
                onBlur={handleBlur}
                name="areaID"
                type="number"
                placeholder="Enter Area ID"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Year ID</Form.Label>
              <Form.Select
                name="yearID"
                onBlur={handleBlur}
                className="me-sm-2"
                id="inlineFormCustomSelect"
              >
                <option value="0">..</option>
                <option value="1">2021-2022</option>
                <option value="2">2022-2023</option>
                <option value="3">2023-2024</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGridEmail">
          <Form.Label>System ID</Form.Label>

          <Form.Control
            onBlur={handleBlur}
            name="sysID"
            type="number"
            placeholder="Enter System ID"
          />
        </Form.Group>

        <Button className="mt-3 me-4 " variant="primary" type="submit">
          Add Area
        </Button>
        <Button className="mt-3 " variant="secondary" type="Cancel">
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default AddAreaInfo;
