import React, { useContext, useEffect, useState } from "react";

import { Accordion, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SideStatus, UserContext } from "../../../App";

// ~importng essential icons from font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faProjectDiagram,
  faArrowCircleRight,
  faBars,
  faCog,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

import logo from "../../../images/logo.png";

import "./Sidebar.css";

const Sidebar = () => {
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);

  // var sidebar = document.getElementById("sideBar");
  // if(loggedInUser.sideBar === true){
  //   sidebar.classList.toggle = "sideBarMobile"
  // }else{
  //   sidebar.classList.toggle = "sideBarMobile"
  // }

  const showSideBAr = () => {};
  return (
    <div>
      <div id="sideBar">
        
        

        <div className="sideBody">
          {/* //?SHowing Accordion on SideBar.   */}
          <h5>
            <FontAwesomeIcon className="fontIcon" icon={faHome} /> House Keeping
          </h5>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="sideBarHeader-list-header">
                <h4 >
                  <FontAwesomeIcon className="fontIcon" icon={faProjectDiagram} /> District
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="list-tems">
                    <Link className="text-link" to="/distrctInfo">District Info</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    <Link className="text-link" to="">District Level</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to="/areaInfo"> Area Info </Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to="/zoneInfo"> Zone Info</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> District Position</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> District Governors</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> District Events</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> Event Registration</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> Upload District Data</Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="list-tems">
                    {" "}
                    <Link className="text-link" to=""> Export District Data</Link>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h4>
                  <FontAwesomeIcon className="fontIcon"  icon={faUserFriends} /> Club
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Club Info</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Club Board Position</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Set Board Position </Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Set Club Board</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Club Year Calender</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Club Events</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Guest Speakers</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Classifications </Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">Memeber Info</Link></ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h4>
                  <FontAwesomeIcon className="fontIcon"  icon={faCog} /> General Settings
                </h4>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">User Management</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">User Permission</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">File Manager</Link></ListGroup.Item>
                  <ListGroup.Item className="list-tems"><Link className="text-link" to="">View Log</Link></ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

       
      </div>

      <div onClick={showSideBAr} className="sideBarArrow">
        <FontAwesomeIcon icon={faBars} />
      </div>
    </div>
  );
};

export default Sidebar;
