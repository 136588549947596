import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../DistrictInfo.css"

const AddDistrictInfo = () => {
    const [info, setInfo] = useState({});

    const handleBlur = e => {
      const newInfo = {...info};
      newInfo[e.target.name] = e.target.value ;
      setInfo(newInfo);
      console.log(info)
    }

    const handleSubmit = e => {
      e.preventDefault();
      const formData = new FormData();

      formData.append("id", info.id);
      formData.append("systemID", info.sysID);
      formData.append("districtID", info.id);
      formData.append("districtName", info.districtName);
      formData.append("description", info.description);
      console.log(info);
      
      /* here Api will be changed with the respected Api*/
      fetch('http://dev.payraapp.com/h/api/district/', {
        method: 'POST',
        // headers: { "Content-Type": "application/json" },
        body: formData
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
        })
        .catch(error => {
            console.error(error)
        })
    
    }

  return (
    <div className="boxContainer">
      <Form onSubmit={handleSubmit}>
         <h2>Add District Info</h2> 
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>District Name</Form.Label>
              <Form.Control onBlur={handleBlur} name="districtName" type="text" placeholder="Enter Name" />
            </Form.Group>
          </Col>

          {/* <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Active</Form.Label>
              <Form.Select name="active" onBlur={handleBlur}  className="me-sm-2" id="inlineFormCustomSelect">
                <option value="0">Choose...</option>
                <option value="1">Active</option>
                <option value="2">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Description</Form.Label>
            
              <Form.Control onBlur={handleBlur} name="description" type="text" placeholder="Enter Description" />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Dist ID</Form.Label>
             
              <Form.Control onBlur={handleBlur} name="id" type="number" placeholder="Enter Description" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGridEmail">
              <Form.Label>System ID</Form.Label>
              
              <Form.Control onBlur={handleBlur} name="sysID" type="number" placeholder="Enter Description" />
            </Form.Group>
       

        <Button className="mt-3 me-4 " variant="primary" type="submit">
          Add District
        </Button>
        <Button className="mt-3 " variant="secondary" type="Cancel">
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default AddDistrictInfo;
