import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";

const AddZoneInfo = () => {
  /*Declaring State for storing data from input field*/
  const [info, setInfo] = useState({});

  /** this will
   * collect data from input field when
   *  onBlur will be activated */
  const handleBlur = (e) => {
    const newInfo = { ...info };
    newInfo[e.target.name] = e.target.value;
    setInfo(newInfo);
    console.log(info);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("id", info.id);
    formData.append("systemID", info.sysID);
    formData.append("yearID", info.yearID);
    formData.append("zoneID", info.zoneID);
    formData.append("zoneName", info.zoneName);
    console.log(info);

    /* here Api will be changed with the respected Api*/
    fetch("http://dev.payraapp.com/h/api/zone/list/", {
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /** */
  return (
    <div className="boxContainer">
      <Form onSubmit={handleSubmit}>
        <h2>Add Zone Info</h2>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Zone Name</Form.Label>
              <Form.Control
                onBlur={handleBlur}
                name="zoneName"
                type="text"
                placeholder="Enter Zone NAame"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>ID</Form.Label>
              <Form.Control
                onBlur={handleBlur}
                name="id"
                type="number"
                placeholder="Enter ID"
              />
            </Form.Group>
          </Col>

          {/* <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Active</Form.Label>
              <Form.Select name="active" onBlur={handleBlur}  className="me-sm-2" id="inlineFormCustomSelect">
                <option value="0">Choose...</option>
                <option value="1">Active</option>
                <option value="2">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Col> */}
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Zone ID</Form.Label>

              <Form.Control
                onBlur={handleBlur}
                name="zoneID"
                type="number"
                placeholder="Enter Zone ID"
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Year ID</Form.Label>
              <Form.Select
                name="yearID"
                onBlur={handleBlur}
                className="me-sm-2"
                id="inlineFormCustomSelect"
              >
                <option value="0">..</option>
                <option value="1">2021-2022</option>
                <option value="2">2022-2023</option>
                <option value="3">2023-2024</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGridEmail">
          <Form.Label>System ID</Form.Label>

          <Form.Control
            onBlur={handleBlur}
            name="sysID"
            type="number"
            placeholder="Enter System ID"
          />
        </Form.Group>

        <Button className="mt-3 me-4 " variant="primary" type="submit">
          Add Zone
        </Button>
        <Button className="mt-3 " variant="secondary" type="Cancel">
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default AddZoneInfo;
