import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./components/Login/Login";
import { createContext, useState } from "react";
import DistrictInfo from "./components/District/DistrictInfo/DistrictInfo";
import PrivateRoute from "./components/Login/PrivateRoute/PrivateRoute";
import Sidebar from "./components/ReUsable/Sidebar/Sidebar";
import Appbar from "./components/ReUsable/Appbar/Appbar";
import Home from "./components/HouseKeepning/Home/Home";
import EditDistrictInfo from "./components/District/EditDistrictInfo/EditDistrictInfo";
import Area from "./components/HouseKeepning/Area/Area";
import Zone from "./components/HouseKeepning/Zone/Zone";

export const UserContext = createContext();

function App() {
  const [loggedInUser, setLoggedInUser] = useState({
    name: "",
    email: "",
    image: "",
    role: "student",
    signedIn: false,
    courses: [],
    sideBar: false,
    districtData:[],
    login:""
  });

  return (
    <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
      
      <Router className="app">
      
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>

          <Route path="/login">
            <Login></Login>
          </Route>
          <PrivateRoute path="/distrctInfo">
            <DistrictInfo></DistrictInfo>
          </PrivateRoute>
          <Route path="/home">
            <Home></Home>
          </Route>
          <Route path="/editDistrictInfo">
            <EditDistrictInfo></EditDistrictInfo>
          </Route>
          <Route path="/areaInfo">
            <Area></Area>
          </Route>
          <PrivateRoute path="/zoneInfo">
            <Zone></Zone>
          </PrivateRoute>
        </Switch>
      
      </Router>
    </UserContext.Provider>
  );
}

export default App;
