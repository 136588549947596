import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
const EditDistrictInfo = ({ data }) => {
  console.log(data.district);
  const { id, systemID, districtID, districtName, description } = data.district;
  const [info, setInfo] = useState({
    id: id,
    systemID: 1,
    districtID: 1,
    districtName: "hello",
    description: "damn",
  });
  const handleCancel = () =>{

  }
  const [edit, setEdit] = useState({});

  //   const { fromNotifications } = location.state;
  //   console.log(fromNotifications)

  const handleBlur = (e) => {
    console.log(e, data);
    const newInfo = { ...info };
    newInfo[e.target.name] = e.target.value;
    setInfo(newInfo);
    console.log(info);
  };
  edit.systemID = info.sysID;
  edit.districtID = info.districtID;
  edit.districtName = info.districtName;
  edit.description = info.description;

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("id", id);
    formData.append("systemID", info.sysID);
    formData.append("districtID", info.districtID);
    formData.append("districtName", info.districtName);
    formData.append("description", info.description);
    console.log(info);
    for (var value of formData.values()) {
      console.log(value);
    }

    /* here Api will be changed with the respected Api*/
    fetch(`http://dev.payraapp.com/h/api/district/${id}`, {
      method: "PUT",
      // headers: { "Content-Type": "application/json" },
      headers: {
        "Content-type": "application/json; charset=UTF-8", // Indicates the content
      },
      body: JSON.stringify(info),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
    
  };

  return (
    <div className="m-5">
      <Form onSubmit={handleSubmit}>
        <h2>Edit District Info</h2>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>District Name</Form.Label>
              <Form.Control
                onBlur={handleBlur}
                name="districtName"
                type="text"
                placeholder={districtName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Description</Form.Label>

              <Form.Control
                onBlur={handleBlur}
                name="description"
                type="text"
                placeholder={description}
              />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Dist ID</Form.Label>

              <Form.Control
                onBlur={handleBlur}
                name="districtID"
                type="number"
                placeholder={districtID}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formGridEmail">
          <Form.Label>System ID</Form.Label>

          <Form.Control
            onBlur={handleBlur}
            name="sysID"
            type="number"
            placeholder={systemID}
          />
        </Form.Group>

        <Button className="mt-3 me-4 " variant="primary" type="submit">
          Save & Change
        </Button>
        <Button className="mt-3 " onClick={handleCancel} variant="secondary" type="Cancel">
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default EditDistrictInfo;
