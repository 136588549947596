import React from "react";

import two from "../../images/rotary.svg";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";

import "./Login.css";
import { useContext } from "react";
import { UserContext } from "../../App";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const Login = () => {
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/home" } };
  const loginInfo = {...loggedInUser};
  console.log(location.state)


  const userInfo = {
    username: "",
    password: "",
  };
  // ~Collecting input text of UserName And Password
  const addUserInfo = (dataName) => {
    if (dataName === "username") {
      userInfo.username = document.getElementById("userName").value;
      console.log(document.getElementById("userName").value);
    } else if (dataName === "password") {
      userInfo.password = document.getElementById("password").value;
    }
  };
 

  //~This Function will do the fetching process and return the authentication result
  const authenticateUser = (e) => {
 

    (async () => {
      const rawResponse = await fetch("http://dev.payraapp.com/user/api/login/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
      });
      const content = await rawResponse.json();
      
      // alert(content.success || content.Error);
      if(content === "user is logged in"){
        history.replace(from)
      }
      console.log(content);
      loggedInUser.login = content.success
      history.replace(from);
    })();
   
    e.preventDefault();
  };


 
 
  // ~Fetching with login data

  return (
    <div className="loginContainer d-flex align-items-center justify-content-center">
      <Container>
        <Form className="loginForm">
          <img src={two} alt="" />
          <br />
          <h2> Rotary Club Administrator Login </h2>
          
          <div className="loginFormGroup">
            <Form.Group className="mb-3">
              <Form.Label className="loginText">User Name</Form.Label>
              <Form.Control
                onBlur={() => addUserInfo("username")}
                id="userName"
                className="loginFormInput p-2 ps-4"
                type="text"
                required
                placeholder="Enter User Name"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="loginText">Password</Form.Label>
              <Form.Control
                onBlur={() => addUserInfo("password")}
                id="password"
                className="loginFormInput p-2 ps-4"
                type="password"
                required
                placeholder="Password"
              />
            </Form.Group>
            {/* Adding forget Password Option */}
            <p id="forgetPass" className="text-warning">
              forgot Password
            </p>

            <Button
              onClick= {authenticateUser}
              className="loginFormButton"
              variant="primary"
              type="submit"
            >
              Sign-In
            </Button>
            
          </div>
        </Form>
        <p>
          <small>&copy;Copyright to Rotary Club. 2021</small>
        </p>
      </Container>
    </div>
  );
};

export default Login;
