import React, { useContext, useState } from "react";
// ~importng essential icons from font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faBell,
  faSortDown,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import img from "../../../images/user.png";

import "./Appbar.css";
import { UserContext } from "../../../App";


import logo from "../../../images/logo.png";

const Appbar = () => {
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
  const [sideBarStatus, SetSideBarStatus] = useState(false);
 

  return (
    <div className="appbar d-flex  align-items-center justify-content-between">
     
     
      {/* 
            //~userName Id will
            //~ be used for showng
            //~  user_Last_name after
            //~ fetching from API
    */}
    <div className="sideHeader">
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <img id="appBarLogo" width="55px" src={logo} alt="" />
            </div>
            <div className="ps-2 mt-3">
              <h4 id="sideBarHeader">
                Welcome to <span className="text-logo">payra!</span>
              </h4>
              {/* //~Here Time will be showed after fetching from API  */}
              <p id="sideText">
                Last Login <span id="loginTime">8:25pm</span>
              </p>
            </div>
          </div>
        </div>
     <div className="d-flex appbarLink">
     <p>
        Welcome <span id="userLastName">Rowly!</span>
      </p>
      <p className="textIcons">
        {" "}
        <FontAwesomeIcon icon={faPlusCircle} />
      </p>
      <p className="textIcons">
        {" "}
        <FontAwesomeIcon icon={faBell} />
      </p>
      <img className="d-block" src={img} alt="" />
      <p>
        <span id="userName"> Md Rowly</span>
      </p>

      <p className="textIcons">
        {" "}
        <FontAwesomeIcon icon={faSortDown} />
      </p>
     </div>
    </div>
  );
};

export default Appbar;
