import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import Appbar from "../../ReUsable/Appbar/Appbar";
import Sidebar from "../../ReUsable/Sidebar/Sidebar";
import "./Home.css";

const Home = () => {
  const [loggedInUser, setLoggedInUser] = useContext(UserContext);
//   fetch('https://jsonplaceholder.typicode.com/posts/1', {
//   method: 'PUT',
//   body: JSON.stringify({
//     id: 1,
//     title: 'footo',
//     body: 'bar',
//     userId: 1,
//   }),
//   headers: {
//     'Content-type': 'application/json; charset=UTF-8',
//   },
// })
//   .then((response) => response.json())
//   .then((json) => console.log(json));


  return (
    <div>
      <Appbar></Appbar>
     <div className="row">
     <div className="col-md-3 col-sm-6 col-12">
          <Sidebar></Sidebar>
        </div>
      <div className="col-md-3 col-sm-6 col-12">
        <div
          className="col-md-8 p-4 d-flex dashboardCard"
          style={{ position: "absolute", right: 0 }}
        >
          <Link className="linkText" to="">
            <h4>Guest Speakers</h4>
          </Link>
          <Link className="linkText" to="">
            {" "}
            <h4>Club Info</h4>
          </Link>
          <Link className="linkText" to="">
            {" "}
            <h4>Content</h4>
          </Link>
          <Link className="linkText" to="">
            {" "}
            <h4>Settings</h4>
          </Link>
          <Link className="linkText" to="">
            <h4>File Manager</h4>
          </Link>
          <Link className="linkText" to="">
            {" "}
            <h4>Users</h4>
          </Link>
          <Link className="linkText" to="">
            {" "}
            <h4>Log</h4>
          </Link>
        </div>
      </div>
     </div>
    </div>
  );
};

export default Home;
