import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull, faArrowCircleDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../ReUsable/Sidebar/Sidebar";
import Appbar from "../../ReUsable/Appbar/Appbar";
import AddDistrictInfo from "../../District/DistrictInfo/AddDistrictInfo/AddDistrictInfo";

import EditDistrictInfo from "../../District/EditDistrictInfo/EditDistrictInfo";

// import "./DistrictInfo.css";
import { Link } from "react-router-dom";
import AddZoneInfo from "./AddZoneInfo/AddZoneInfo";
import EditZoneInfo from "./EditZoneInfo/EditZoneInfo";

const Zone = () => {

    /** */
    const [datas, setDatas] = useState([]);

    const [editStatus, SetEditStatus] = useState(false);
    const [editData, setEditData] = useState([]);
  
    /**Here data will be imported
     *  from api with fetimport { Button } from 'react-bootstrap/Button';
  ching methodimport EditDistrictInfo from '../EditDistrictInfo/EditDistrictInfo';
  
     */
  
    useEffect(() => {
      // Update the District info using the browser API and tore date in state
      fetch("http://dev.payraapp.com/h/api/zone/list/")
        .then((res) => res.json())
        .then((data) => setDatas(data));
    }, []);
  
    /**Editing Part will Start from here */
    const handleEdit = (editableZone) => {
      console.log(editableZone);
      SetEditStatus(true);
      setEditData(editableZone);
  
      const formData = new FormData();
  
      formData.append("id", editableZone.id);
      formData.append("systemID", editableZone.sysID);
      formData.append("zoneID", editableZone.zoneID);
      formData.append("yearID", editableZone.yearID);
      formData.append("zoneName", editableZone.zoneName);
      console.log(editableZone);
      
    };
  
    /** */
  
    /**Deleting Part will start from here */
    const handleDelete = (deleteZone) => {
      console.log(deleteZone);
      const deleteId = deleteZone.district.id;
      console.log();
  
      fetch(`http://dev.payraapp.com/h/api/zone/list/${deleteId}`, {
        method: "DELETE",
      })
        .then((res) => res.json()) // or res.json()
        .then((res) => alert("element has been deleted"));
    };
  
    /** */
    return (
        <div className="districtInfo">
      <Appbar></Appbar>
      <div className="row">
        <div className="col-xl-2 col-l-3 col-md-4 col-sm-6 col-12">
          <Sidebar></Sidebar>
        </div>

        <div className="col-xl-10 col-l-9 col-md-8 col-m-12 col-sm-12 col-12 p-1">
          {!editStatus ? (
            <AddZoneInfo
              style={{ width: "70%", right: 0 }}
            ></AddZoneInfo>
          ) : (
            <EditZoneInfo data={editData}></EditZoneInfo>
          )}

          <div className="districtTableContainer">
            <div className="mt-5">
              <h3> Zone Info Manager </h3>
              
              <Button className="mt-3 me-2 " variant="danger" type="submit">
                Delete
              </Button>
              <Button className="mt-3 " variant="secondary" type="submit">
                Draft
              </Button>
              <div></div>
            </div>
            <Table responsive="xl">
              <thead>
                <tr>
                  <th className="table-head">
                    <FontAwesomeIcon icon={faSquareFull} />
                  </th>
                  <th className="table-head">ID <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">District Name <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Description <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">District ID <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Edit <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Delete <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                </tr>
              </thead>
              <tbody className="table-body">
                {/* /* Here the data will be showed with map method*/}
                {datas.map((zone, index) => (
                  <tr className="table-row">
                    <td></td>
                    <td>{index}</td>
                    <td>{zone.zoneName}</td>
                    <td>{zone.yearID}</td>
                    <td>{zone.zoneID}</td>

                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleEdit({ zone })}
                      >
                        Edit
                      </Button>
                    </td>

                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete({ zone })}
                      >
                       Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      </div>
    )
}

export default Zone
