import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull, faArrowCircleDown, faTrash } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../ReUsable/Sidebar/Sidebar";
import Appbar from "../../ReUsable/Appbar/Appbar";
import AddDistrictInfo from "./AddDistrictInfo/AddDistrictInfo";

import EditDistrictInfo from "../EditDistrictInfo/EditDistrictInfo";

import "./DistrictInfo.css";
import { Link } from "react-router-dom";

const DistrictInfo = () => {
  const [datas, setDatas] = useState([]);

  const [editStatus, SetEditStatus] = useState(false);
  const [editData, setEditData] = useState([]);

  /**Here data will be imported
   *  from api with fetimport { Button } from 'react-bootstrap/Button';
ching methodimport EditDistrictInfo from '../EditDistrictInfo/EditDistrictInfo';

   */

  useEffect(() => {
    // Update the District info using the browser API and tore date in state
    fetch("http://dev.payraapp.com/h/api/district/")
      .then((res) => res.json())
      .then((data) => setDatas(data));
  }, []);

  /**Editing Part will Start from here */
  const handleEdit = (editableDistrict) => {
    console.log(editableDistrict);
    SetEditStatus(true);
    setEditData(editableDistrict);

    const formData = new FormData();

    formData.append("id", editableDistrict.id);
    formData.append("systemID", editableDistrict.sysID);
    formData.append("districtID", editableDistrict.id);
    formData.append("districtName", editableDistrict.districtName);
    formData.append("description", editableDistrict.description);
    console.log(editableDistrict);
    
  };

  /** */

  /**Deleting Part will start from here */
  const handleDelete = (deleteDistrict) => {
    console.log(deleteDistrict);
    const deleteId = deleteDistrict.district.id;
    console.log();

    fetch(`http://dev.payraapp.com/h/api/district/${deleteId}`, {
      method: "DELETE",
    })
      .then((res) => res.json()) // or res.json()
      .then((res) => alert("element has been deleted"));
  };

  return (
    <div className="districtInfo">
      <Appbar></Appbar>
      
      <div className="row">
        <div className="col-xl-2 col-l-3 col-md-4 col-sm-6 col-12">
          <Sidebar></Sidebar>
        </div>

        <div className="col-xl-10 col-l-9 col-md-8 col-m-12 col-sm-12 col-12 p-1">
          {!editStatus ? (
            <AddDistrictInfo
              style={{ width: "70%", right: 0 }}
            ></AddDistrictInfo>
          ) : (
            <EditDistrictInfo data={editData}></EditDistrictInfo>
          )}

          <div className="districtTableContainer">
            <div className="mt-5">
              <h3> District Info Manager </h3>
              
              <Button className="mt-3 me-2 " variant="danger" type="submit">
                Delete
              </Button>
              <Button className="mt-3 " variant="secondary" type="submit">
                Draft
              </Button>
              <div></div>
            </div>
            <Table responsive="xl">
              <thead>
                <tr>
                  <th className="table-head">
                    <FontAwesomeIcon icon={faSquareFull} />
                  </th>
                  <th className="table-head">ID <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">District Name <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Description <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">District ID <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Edit <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                  <th className="table-head">Delete <FontAwesomeIcon icon={faArrowCircleDown} /></th>
                </tr>
              </thead>
              <tbody className="table-body">
                {/* /* Here the data will be showed with map method*/}
                {datas.map((district, index) => (
                  <tr className="table-row">
                    <td></td>
                    <td>{index}</td>
                    <td>{district.districtName}</td>
                    <td>{district.description}</td>
                    <td>{district.districtID}</td>

                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleEdit({ district })}
                      >
                        Edit
                      </Button>
                    </td>

                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete({ district })}
                      >
                       Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="col-md-9 col-sm-12 col-12"></div>
    </div>
  );
};

export default DistrictInfo;
