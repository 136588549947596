import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddDistrictInfo from "../../District/DistrictInfo/AddDistrictInfo/AddDistrictInfo";
import Appbar from "../../ReUsable/Appbar/Appbar";
import Sidebar from "../../ReUsable/Sidebar/Sidebar";
import AddAreaInfo from "./AddAreaInfo/AddAreaInfo";
import "./Area.css";


const Area = () => {
  const [datas, setDatas] = useState([]);
  const [editData, SetEditData] = useState([]);

  /**Here data will be imported
   *  from api with fetimport { Button } from 'react-bootstrap/Button';
ching methodimport { useEffect } from 'react';
import { useEffect } from 'react';
import Appbar from '../../ReUsable/Appbar/Appbar';
import Sidebar from '../../ReUsable/Sidebar/Sidebar';
import { Button } from 'react-bootstrap/Button';
import { useState } from 'react';
import AddAreaInfo from './AddAreaInfo/AddAreaInfo';

   */

  useEffect(() => {
    // Update the District info using the browser API and tore date in state
    fetch("http://dev.payraapp.com/h/api/area/list/")
      .then((res) => res.json())
      .then((data) => setDatas(data));
  }, []);
  console.log(datas)

  /**Editing Part will Start from here */
  const handleEdit = (editableDistrict) => {
    console.log(editableDistrict);

    const formData = new FormData();

    formData.append("id", editableDistrict.id);
    formData.append("systemID", editableDistrict.sysID);
    formData.append("districtID", editableDistrict.id);
    formData.append("districtName", editableDistrict.districtName);
    formData.append("description", editableDistrict.description);
    console.log(editableDistrict);
  };

  /** */

  /**Deleting Part will start from here */
  const handleDelete = (deleteDistrict) => {
    console.log(deleteDistrict);
    const deleteId = deleteDistrict.area.id;
    console.log();

    fetch(`http://dev.payraapp.com/h/api/area/list/${deleteId}`, {
      method: "DELETE",
    })
      .then((res) => res.json()) // or res.json()
      .then((res) => console.log(res));
  };

  return (
    <div>
      <div className="districtInfo">
        <Appbar></Appbar>
        <div className="row">
          <div className="col-xl-2 col-l-3 col-md-4 col-sm-6 col-12">
            <Sidebar></Sidebar>
          </div>

          <div className="col-xl-10 col-l-9 col-md-8 col-m-12 col-sm-12 col-12 p-1">
            <AddAreaInfo
              style={{ width: "70%", right: 0 }}
            ></AddAreaInfo>

            <div className="districtTableContainer">
              <div className="mt-5">
                <h3> Area Info Manager </h3>
                
                <Button className="mt-3 me-2 " variant="danger" type="submit">
                  Delete
                </Button>
                <Button className="mt-3 " variant="secondary" type="submit">
                  Draft
                </Button>
                <div></div>
              </div>
              <Table responsive="xl">
                <thead>
                  <tr>
                    <th className="table-head">{/* <FontAwesomeIcon icon={faSquareFull} /> */}</th>
                    <th className="table-head">ID</th>
                    <th className="table-head">Area Name </th>
                    <th className="table-head">YearID</th>
                    <th className="table-head">AreaID </th>
                    <th className="table-head">Edit</th>
                    <th className="table-head">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {/* /* Here the data will be showed with map method*/}
                  {datas.map((area, index) => (
                    <tr>
                      <td></td>
                      <td>{index}</td>
                      <td>{area.areaName}</td>
                      <td>{area.yearID}</td>
                      <td>{area.areaID}</td>

                      <td>
                        <Link to="editDistrictInfo" params={{ area }}>
                          <Button
                            variant="primary"
                            onClick={() => handleEdit({ area })}
                          >
                            Edit
                          </Button>
                        </Link>
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          onClick={() => handleDelete({ area })}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Area;
